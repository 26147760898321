import { BButton } from '@/components/bootstrap'
import ContextMenu from '@/components/ContextMenu'
import DateTimePicker from '@/components/controls/DateTimePicker'
import { copyTextToClipboard } from '@/helpers/copy'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { sprintsStore } from '@/store'
import { Lesson, Sprint } from '@/store/sprintsStore'
import { Component, Prop, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

@Component
export default class LessonView extends tsx.Component<{
  lesson: Lesson
  sprint: Sprint
  onRemoveClick: (lesson: Lesson) => void
}> {
  @Ref() readonly dateTimePicker!: DateTimePicker

  @Prop({ type: Object, required: true })
  readonly lesson!: Lesson
  @Prop({ type: Object, required: true })
  readonly sprint!: Sprint

  showDateInput = false
  date = ''
  pending = false

  get isWrongDate() {
    const index = this.sprint.lessons.findIndex(
      lesson => lesson === this.lesson,
    )
    const nextLesson = this.sprint.lessons[index + 1]
    if (nextLesson) {
      const date = new Date(this.lesson.date_start)
      const nextDate = new Date(nextLesson.date_start)

      return date > nextDate
    }

    return false
  }

  get dateStartFormatted() {
    return new Date(this.lesson.date_start).toLocaleDateString('ru')
  }

  get typeText() {
    switch (this.lesson.type) {
      case 1:
        return 'ДЗ'
      case 2:
        return 'Тест'
      case 3:
        return 'Нет задания'
    }
  }

  get typeBadgeVariant() {
    switch (this.lesson.type) {
      case 1:
        return 'light-success'
      case 2:
        return 'light-info'
      case 3:
        return 'light-warning'
    }
  }

  mounted() {
    this.date = this.lesson.date_start
  }

  open() {
    this.showDateInput = true
    this.$nextTick(() => this.dateTimePicker.open())
  }

  async saveDate() {
    try {
      this.showDateInput = false
      this.pending = true
      await axios.patch(`v2/lessons/${this.lesson.id}`, {
        access: { startDate: this.date },
      })

      this.lesson.date_start = this.date
    } catch (error) {
      console.error(error)
      showError('При изменении даты произошла ошибка')
    }
    this.pending = false
  }

  onClickOutside(e: PointerEvent) {
    try {
      const path = e.composedPath()
      //@ts-ignore
      if (path.some(el => el.className?.includes('flatpickr'))) {
        return
      }

      this.date = this.lesson.date_start
      this.showDateInput = false
    } catch (error) {}
  }

  protected render() {
    return (
      <div class="lesson-view p-25 border rounded d-sm-flex align-items-center">
        <div class="d-flex align-items-center flex-grow-1">
          <feather-icon
            size="25"
            icon="MoreVerticalIcon"
            class="drag-handle flex-shrink-0 mr-25"
            style={{ opacity: sprintsStore.search ? 0 : 1 }}
          />
          <b-badge pill variant="light-secondary">
            {this.lesson.index}
          </b-badge>

          <span class="flex-grow-1 mx-50">
            {this.lesson.title}
            <ContextMenu
              options={[
                {
                  label: 'Скопировать ссылку на платформу',
                  icon: 'CopyIcon',
                  action: () =>
                    copyTextToClipboard(
                      `${process.env.VUE_APP_LPC_URL}/lesson/${this.lesson.id}`,
                    ),
                },
              ]}
            />
          </span>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-badge variant={this.typeBadgeVariant} pill class="mr-50">
            {this.typeText}
          </b-badge>

          {this.isWrongDate && (
            <b-badge
              variant="danger"
              pill
              class="mr-25"
              v-b-tooltip
              title="Дата выхода этого урока позже даты выхода следующего урока"
            >
              !
            </b-badge>
          )}

          {this.pending ? (
            <b-spinner small class="mr-50" />
          ) : (
            <div class="date-wrapper mr-50">
              <b-badge
                pill
                variant="light-secondary"
                onClick={this.open}
                class="cursor-pointer"
              >
                {this.dateStartFormatted}
              </b-badge>

              {this.showDateInput && (
                <div
                  class="date-input border p-25 rounded shadow d-flex"
                  v-click-outside={(e: PointerEvent) => this.onClickOutside(e)}
                >
                  <DateTimePicker
                    ref="dateTimePicker"
                    value={this.date}
                    onInput={value => (this.date = value)}
                    enableTime
                    dateFormat="Y-m-d H:i:S"
                    displayedFormat="l d M Y H:i"
                  />
                  <BButton
                    variant="success"
                    onClick={this.saveDate}
                    class="px-50 ml-25"
                  >
                    <feather-icon icon="CheckIcon" />
                  </BButton>
                </div>
              )}
            </div>
          )}

          <BButton
            variant="flat-primary"
            to={{
              name: 'editLesson',
              params: {
                streamId: this.lesson.streamId.toString(),
                id: this.lesson.id.toString(),
              },
            }}
            class="p-50"
          >
            <feather-icon icon="Edit3Icon" />
          </BButton>
          <BButton
            variant="flat-danger"
            class="p-50"
            onClick={() => this.$emit('removeClick', this.lesson)}
          >
            <feather-icon icon="TrashIcon" />
          </BButton>
        </div>
      </div>
    )
  }
}
