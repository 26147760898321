import { BButton } from '@/components/bootstrap'
import Draggable, { MovedEvent } from '@/components/Draggable'
import CollapseTransition from '@/components/transitions/Collapse'
import declination from '@/helpers/declination'
import { loadFromLS, saveToLS } from '@/helpers/localStorage'
import { sprintsStore } from '@/store'
import { Lesson, Sprint } from '@/store/sprintsStore'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import LessonView from '../LessonView'
import './style.scoped.scss'

@Component
export default class SprintView extends tsx.Component<{
  sprint: Sprint
  onRemoveClick: (sprint: Sprint) => void
  onLessonRemoveClick: (lesson: Lesson) => void
  onEditClick: (sprint: Sprint) => void
}> {
  @Prop({ type: Object, required: true })
  readonly sprint!: Sprint

  get filteredLessons() {
    if (!sprintsStore.search) {
      return this.sprint.lessons
    }

    return this.sprint.lessons.filter(lesson =>
      lesson.title.toLowerCase().includes(sprintsStore.search.toLowerCase()),
    )
  }

  get lessonsCountText() {
    const lessonsCount = this.sprint.lessons.length

    return `${lessonsCount} ${declination(lessonsCount, [
      'урок',
      'урока',
      'уроков',
    ])}`
  }

  async onLessonMoved(e: MovedEvent<Lesson>) {
    if (e.removed) {
      return
    }

    const element = e.added?.element || e.moved?.element!
    const newIndex = e.added?.newIndex || e.moved?.newIndex!
    const position = newIndex
      ? this.sprint.lessons[newIndex - 1].position + 1
      : 0

    if (e.added) {
      await sprintsStore.changeLessonSprint({
        id: element.id,
        sprintId: this.sprint.id,
      })
    }

    await sprintsStore.changeLessonPosition({
      id: element.id,
      position,
    })
  }

  toogleCollapse() {
    this.sprint.collapsed = !this.sprint.collapsed

    const collapsedStates =
      loadFromLS<Record<number, boolean>>('sprintCollapseStates') || {}
    collapsedStates[this.sprint.id] = this.sprint.collapsed
    saveToLS('sprintCollapseStates', collapsedStates)
  }

  protected render() {
    return (
      <div
        class={[
          'sprint-view p-25 border rounded shadow',
          { open: !this.sprint.collapsed },
        ]}
      >
        <div class="d-sm-flex align-items-center">
          <div class="d-flex align-items-center flex-grow-1">
            <BButton
              variant="flat-secondary"
              disabled={!!sprintsStore.search}
              onClick={this.toogleCollapse}
              class="p-50 mr-25 transition"
              style={{ opacity: sprintsStore.search ? 0 : 1 }}
            >
              <feather-icon
                icon="ChevronUpIcon"
                class={['transition', { 'rotate-180': this.sprint.collapsed }]}
              />
            </BButton>
            <b-badge pill variant="light-secondary" class="drag-handle">
              {this.sprint.index}
            </b-badge>

            <div class="flex-grow-1 mx-50 drag-handle font-weight-bold">
              {this.sprint.title.ru}
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <small class="lessons-count">{this.lessonsCountText}</small>

            <BButton
              variant="flat-success"
              to={{
                name: 'newLesson',
                params: {
                  streamId: this.sprint.stream_id.toString(),
                },
                query: { sprintId: this.sprint.id.toString() },
              }}
              class="p-50"
            >
              <feather-icon icon="PlusIcon" />
            </BButton>

            <BButton
              onClick={() => this.$emit('editClick', this.sprint)}
              variant="flat-primary"
              class="p-50"
            >
              <feather-icon icon="Edit3Icon" />
            </BButton>
            <BButton
              onClick={() => this.$emit('removeClick', this.sprint)}
              variant="flat-danger"
              class="p-50"
              style={{ marginRight: '4px' }}
            >
              <feather-icon icon="TrashIcon" />
            </BButton>
          </div>
        </div>

        <CollapseTransition hidden>
          {(!this.sprint.collapsed || !!sprintsStore.search) && (
            <div>
              {!this.sprint.lessons.length && !sprintsStore.lessonDragged && (
                <div class="py-1 text-center w-100">У спринта нет уроков</div>
              )}
              <Draggable
                value={this.filteredLessons}
                onInput={value => (this.sprint.lessons = value)}
                group="lessons"
                onMoved={this.onLessonMoved}
                onStart={() => (sprintsStore.lessonDragged = true)}
                onEnd={() => (sprintsStore.lessonDragged = false)}
                showDropZone={sprintsStore.lessonDragged}
                dropZoneHeight="43px"
                disabled={!!sprintsStore.search}
                scopedSlots={{
                  item: ({ item }: { item: Lesson }) => (
                    <LessonView
                      lesson={item}
                      sprint={this.sprint}
                      key={item.id}
                      onRemoveClick={() =>
                        this.$emit('lessonRemoveClick', item)
                      }
                    />
                  ),
                }}
                class={{ 'pt-1': this.sprint.lessons.length }}
              />
            </div>
          )}
        </CollapseTransition>
      </div>
    )
  }
}
