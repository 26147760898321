import declination from '@/helpers/declination'
import { sprintsStore } from '@/store'
import { Lesson } from '@/store/sprintsStore'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class SprintsFooter extends tsx.Component<{}> {
  get lessons() {
    const lessons: Lesson[] = []
    sprintsStore.sprints.forEach(sprint => lessons.push(...sprint.lessons))
    return lessons
  }

  get lessonsCountText() {
    const count = this.lessons.length
    return `${count} ${declination(count, ['урок', 'урока', 'уроков'])}`
  }

  get testsCountText() {
    const count = this.lessons.filter(lesson => lesson.type === 2).length
    return `${count} ${declination(count, ['тест', 'теста', 'тестов'])}`
  }

  get homeworksCountText() {
    const count = this.lessons.filter(lesson => lesson.type === 1).length
    return `${count} ДЗ`
  }

  protected render() {
    return (
      <div class="d-flex align-items-center mt-1">
        <span class="mr-50">Всего:</span>
        <b-badge variant="light-secondary" pill class="mr-25">
          {this.lessonsCountText}
        </b-badge>
        <b-badge variant="light-success" pill class="mr-25">
          {this.homeworksCountText}
        </b-badge>
        <b-badge variant="light-info" pill>
          {this.testsCountText}
        </b-badge>
      </div>
    )
  }
}
