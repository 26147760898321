import { BButton, BCard, BInput, BOverlay } from '@/components/bootstrap'
import Draggable, { MovedEvent } from '@/components/Draggable'
import SprintView from '@/components/sprints/SprintView'
import { sprintsStore } from '@/store'
import { Lesson, Sprint } from '@/store/sprintsStore'
import { Component, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal'
import './style.scoped.scss'
import SprintsFooter from '@/components/sprints/Footer'
import SprintEditModal from '@/components/sprints/SprintEditModal'
import { loadFromLS, saveToLS } from '@/helpers/localStorage'

@Component
export default class SprintsPage extends tsx.Component<{}> {
  @Ref() readonly deleteConfirmationModal!: DeleteConfirmationModal
  @Ref() readonly sprintEditModal!: SprintEditModal

  get isSprintsCollapsed() {
    return sprintsStore.sprints.every(sprint => sprint.collapsed)
  }

  get filteredSprints() {
    if (!sprintsStore.search) {
      return sprintsStore.sprints
    }

    return sprintsStore.sprints.filter(sprint =>
      sprint.lessons.some(lesson =>
        lesson.title.toLowerCase().includes(sprintsStore.search.toLowerCase()),
      ),
    )
  }

  async onSprintMoved(e: MovedEvent<Sprint>) {
    const position = e.moved!.newIndex
      ? sprintsStore.sprints[e.moved!.newIndex - 1].position + 1
      : 0

    await sprintsStore.changeSprintPosition({
      id: e.moved!.element.id,
      position,
    })
  }

  collapseButtonClick() {
    const isSprintsCollapsed = this.isSprintsCollapsed
    const collapsedStates =
      loadFromLS<Record<number, boolean>>('sprintCollapseStates') || {}

    sprintsStore.sprints.forEach(sprint => {
      sprint.collapsed = isSprintsCollapsed ? false : true

      collapsedStates[sprint.id] = sprint.collapsed
    })

    saveToLS('sprintCollapseStates', collapsedStates)
  }

  async removeSprint(sprint: Sprint) {
    const result = await this.deleteConfirmationModal.show(sprint.title.ru!)
    if (result) {
      await sprintsStore.removeSprint(sprint.id)
    }
  }

  async removeLesson(lesson: Lesson) {
    const result = await this.deleteConfirmationModal.show(lesson.title)
    if (result) {
      await sprintsStore.removeLesson(lesson.id)
    }
  }

  protected render() {
    return (
      <BCard>
        <div class="d-sm-flex mb-1">
          <div class="d-flex mb-25 mb-sm-0">
            <BButton
              variant="outline-secondary"
              class="px-50 mr-25 no-wrap"
              onClick={this.collapseButtonClick}
            >
              <feather-icon
                icon="ChevronUpIcon"
                class={[
                  'transition',
                  { 'rotate-180': this.isSprintsCollapsed },
                ]}
              />
            </BButton>
            <BButton
              variant="primary"
              class="mr-sm-1 flex-grow-1 no-wrap"
              onClick={() => this.sprintEditModal.show()}
            >
              Создать спринт
            </BButton>
          </div>

          <BInput
            value={sprintsStore.search}
            onInput={value => {
              sprintsStore.search = value
            }}
            placeholder="Поиск по урокам"
          />
        </div>
        <BOverlay show={sprintsStore.pending}>
          <Draggable
            value={this.filteredSprints}
            onInput={value => (sprintsStore.sprints = value)}
            disabled={!!sprintsStore.search}
            onMoved={this.onSprintMoved}
            scopedSlots={{
              item: ({ item }: { item: Sprint }) => (
                <SprintView
                  sprint={item}
                  key={item.id}
                  onRemoveClick={this.removeSprint}
                  onLessonRemoveClick={this.removeLesson}
                  onEditClick={sprint => this.sprintEditModal.show(sprint)}
                />
              ),
            }}
          />
        </BOverlay>

        <SprintsFooter />

        <SprintEditModal ref="sprintEditModal" />
        <DeleteConfirmationModal ref="deleteConfirmationModal" />
      </BCard>
    )
  }
}
