import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { BModal, BOverlay } from '@/components/bootstrap'
import { FieldValidator, notEmpty, ValidationGroup } from '@/helpers/validation'
import { BvEvent } from 'bootstrap-vue'
import { emptyLocalizedField } from '@/store/i18nStore'
import { Sprint } from '@/store/sprintsStore'
import FormInput from '../controls/form/FormInput'
import { showError } from '@/helpers/notifications'
import { sprintsStore } from '@/store'
import axios from '@/libs/axios'

@Component
export default class SprintEditModal extends tsx.Component<{
  show?: (sprint?: Sprint) => Promise<void>
}> {
  modalId = 'promocode-modal'
  state: 'new' | 'edit' = 'new'
  pending = false
  sprintId = -1

  validator = new ValidationGroup()

  title = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.validator,
    localized: true,
  })
  description = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.validator,
    localized: true,
  })

  get modalTitle() {
    if (this.state === 'new') {
      return 'Создать спринт'
    }
    return 'Редактировать спринт'
  }

  get okButtonTitle() {
    if (this.state === 'new') {
      return 'Создать'
    }
    return 'Сохранить'
  }

  async show(sprint?: Sprint) {
    this.state = sprint ? 'edit' : 'new'

    if (sprint) {
      this.sprintId = sprint.id
      this.title.set(sprint.title)
      this.description.set(sprint.description)
    }

    this.$bvModal.show(this.modalId)
  }

  async submit(e: BvEvent) {
    e.preventDefault()

    if (!this.validator.validate()) {
      showError('При заполнении полей допущены ошибки')
      return
    }

    this.pending = true
    let result = false

    if (this.state === 'new') {
      result = await this.createSprint()
    } else {
      result = await this.saveSprint()
    }

    if (result) {
      await sprintsStore.fetchSprintsAndLessons(sprintsStore.streamId)
    }

    this.pending = false

    if (result) {
      this.$bvModal.hide(this.modalId)
    }
  }

  async createSprint() {
    try {
      await axios.post('v2/sprints', {
        streamId: sprintsStore.streamId,
        title: this.title.value,
        description: this.description.value,
      })
      return true
    } catch (error) {
      console.error(error)
      showError('При создании спринта произошла ошибка')
      return false
    }
  }

  async saveSprint() {
    try {
      await axios.patch(`v2/sprints/${this.sprintId}`, {
        streamId: sprintsStore.streamId,
        title: this.title.value,
        description: this.description.value,
      })
      return true
    } catch (error) {
      console.error(error)
      showError('При сохранении спринта произошла ошибка')
      return false
    }
  }

  onHidden() {
    this.title.set(emptyLocalizedField())
    this.description.set(emptyLocalizedField())
    this.sprintId = -1

    this.pending = false

    this.$nextTick(() => this.validator.resetErrors())
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        ok-title={this.okButtonTitle}
        cancel-title="Отмена"
        centered
        title={this.modalTitle}
        onHidden={this.onHidden}
        onOk={this.submit}
        busy={this.pending}
      >
        <BOverlay show={this.pending} no-wrap />

        <FormInput label="Название" fieldValidator={this.title} />
        <FormInput
          label="Описание"
          fieldValidator={this.description}
          textarea
        />
      </BModal>
    )
  }
}
